import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ROUTER_UTILS } from "../utils/router.utils";
import { AuthService } from 'app/pages/auth/services/auth.service';
import { AuthUser } from "app/shared/models/auth-user";
import { removeItem, StorageItem } from "../utils";

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if ([401].includes(error.status)) { // 403 ?

            removeItem(StorageItem.Auth);
            removeItem(StorageItem.User);
            this.authService.isLoggedIn$.next(false);
            this.authService.user$.next(new AuthUser());
            if (!request.url.includes('auth/login')) {
              window.location.href = '/auth/login';
            }
          // this.router.navigate(['/', ROUTER_UTILS.config.auth.root, ROUTER_UTILS.config.auth.signIn])
          return throwError(error);
        } else if (error.status === 500) {
          console.error(error);
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
