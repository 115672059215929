import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  getItem,
  removeItem,
  setItem,
  StorageItem,
  getUser,
} from 'app/core/utils';
import { IAuthUser, AuthUser } from 'app/shared/models/auth-user';
import { SERVER_API_URL } from 'app/app.constants';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoginResponse } from 'app/shared/models/login-response';
import { LoginRequest } from 'app/shared/models/login-request';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}
  isLoggedIn$ = new BehaviorSubject<boolean>(!!getItem(StorageItem.Auth));
  user$ = new BehaviorSubject<IAuthUser>(getUser(StorageItem.User));

  get isLoggedIn(): boolean {
    return this.isLoggedIn$.getValue();
  }

  get user(): unknown {
    return this.user$.getValue();
  }

  signIn(user: LoginRequest): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(SERVER_API_URL + 'auth/login', user)
      .pipe(
        map((response) => {
          setItem(StorageItem.Auth, response.token);
          this.isLoggedIn$.next(true);
          let savedUser = new AuthUser(
            user.email,
            response.displayName,
            response.role
          );
          setItem(StorageItem.User, savedUser);
          this.user$.next(savedUser);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  signOut(): void {
    removeItem(StorageItem.Auth);
    removeItem(StorageItem.User);
    this.isLoggedIn$.next(false);
    this.user$.next(new AuthUser());
  }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);

    if (!!error.error && !!error.error.message) {
      const errMessage = error.error.message;
      return throwError(errMessage);
    }
    return throwError(error || 'Server error');
  }
}
