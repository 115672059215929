import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalService } from "app/shared/components/modal/modal.service";
import { SettingsService } from "app/shared/services/settings.service";
import { AuthService } from "../auth/services/auth.service";
import { HomeService } from "../home/services/home.service";


@Component({
    templateUrl: "./settings.page.html",
    styleUrls: ["./settings.page.scss"],
})
export class SettingsPage implements OnInit {
    brandDetails: any = {
      facebookAddress: '',
      instagramAddress: '',
      linkedinAddress: '',
      name: '',
      twitterAddress: '',
      websiteAddress: ''
    };
    title: string = '';
    email: string = '';
    weeklyEmail: string = '';
    form: any = {
      aiColorSectionExpanded: true,
      emotionalIntensitySectionExpanded: true,
      receptivityForecastSectionExpanded: true,
      upgradeSectionExpanded: true,
      postSuffixes: {
        additionalProp1: "",
        additionalProp2: "",
        additionalProp3: ""
      },
      postingFrequency: "DAILY_POSTING",
      socialNetworkCredentials: {},
      reminderEmails: [],
      // weeklyDigestDays: [],
      // weeklyDigestEmails: [],
      // weeklyDigestTime: ''
    };
    modalTitle = '';
    modalMessage = '';
    userInfo: any = {
      displayName: '',
      email: '',
      license: '',
      numOfTargetGroupsLeft: 0,
      socialNetworks: [],
      userId: ''
    };
    socialNetworksCredentials: any[] = [];
    credentialForm: any = {
      network: '',
      username: '',
      password: ''
    }
    constructor(
        private router: Router,
        private authService: AuthService,
        private homeService: HomeService,
        private settingsService: SettingsService,
        private modalService: ModalService
    ) {
    }


  ngOnInit(): void {
    this.getSettings();
    this.getBrandDetails();
    this.getUserInfo();
    this.fixPlaceholders();
  }


  addEmail(): void {
    if (!!this.email) {
      this.form.reminderEmails.push(this.email);
      this.email = '';
    }
  }

  // addWeeklyEmail(): void {
  //   if (!!this.weeklyEmail) {
  //     this.form.weeklyDigestEmails.push(this.weeklyEmail);
  //     this.weeklyEmail = '';
  //   }
  // }

  // addDay(day: string): void {
  //   const index = this.form.weeklyDigestDays.indexOf(day);
  //   if (index !== -1) {
  //     this.form.weeklyDigestDays.splice(index, 1);
  //   } else {
  //     this.form.weeklyDigestDays.push(day);
  //   }
  // }

  fixPlaceholders(): void {
    let textAreas = document.getElementsByTagName('textarea');

    Array.prototype.forEach.call(textAreas, function(elem) {
        elem.placeholder = elem.placeholder.replace(/\\n/g, '\n');
    });
  }

  getSettings(): void {
    this.settingsService.getSettings().subscribe((response) => {
      if (response.body) {
        this.form = response.body;
      }
    })
  }

  getBrandDetails(): void {
    this.settingsService.getBrandDetails().subscribe((response) => {
      if (response && response.body) {
        this.brandDetails = response.body;
      }
    })
  }

  // updatePostTitles(): void {
  //   this.form.postTitles = ['Facebook', 'Instagram', 'Twitter', 'LinkedIn', 'Youtube', 'TikTok', 'Telgram', 'Reddit', 'Pinterest']
  //   this.settingsService.updateSettings(this.form).subscribe((response) => {});
  // }

  updateSettings(): void {
    this.addEmail();
    // this.addWeeklyEmail();
    this.form.weeklyDigestTime = this.form.weeklyDigestTime + ' Europe/Belgrade';
    this.settingsService.updateSettings(this.form).subscribe((response) => {
      if (response && response.body) {
        this.modalTitle = 'Rezonate.me';
        this.modalMessage = 'Settings successfully saved!';
        this.openModal('info-modal');
        this.getSettings();
      } else {
        this.modalTitle = 'Error';
        this.modalMessage = 'An error occured.';
        this.openModal('info-modal');
      }
    }, (error) => {
      let title =  'Error';
      let message = error.error?.errors?.join('<br>') ?? 'An error occured.';
      this.modalTitle = title;
      this.modalMessage = error.error?.message ?? message;
      this.openModal('info-modal');
    })
  }

  updateBrandDetails(): void {
    this.settingsService.updateBrandDetails(this.brandDetails).subscribe((response) => {
      if (response && response.body) {
        this.modalTitle = 'Rezonate.me';
        this.modalMessage = 'Brand data successfully saved!';
        this.openModal('info-modal');
      } else {
        this.modalTitle = 'Error';
        this.modalMessage = 'An error occured.';
        this.openModal('info-modal');
      }
    }, (error) => {
      let title = error.error?.message ?? 'Error';
      let message = error.error?.errors?.join('<br>') ?? 'An error occured.';
      this.modalTitle = title;
      this.modalMessage = message;
      this.openModal('info-modal');
    })
  }

  getUserInfo(): void {
    this.homeService.getUserInfo().subscribe(
      (response) => {
        if (response.body) {
          this.userInfo = response.body;
        }
      }
    );
  }

  openCredentialModal(network: string): void {
    this.credentialForm.network = network;
    this.openModal('credential-modal');
  }

  saveCredentials(): void {
    console.log(this.credentialForm);
    this.closeModal('credential-modal')
  }


  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalTitle = '';
    this.modalMessage = '';
    this.modalService.close(id);
  }
}
