import { Component, OnInit, Input, Output, EventEmitter, HostListener, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownComponent),
  multi: true
};


export class DropdownValue {
  value: string;
  label: string;

  constructor(value: string, label: string) {
    this.value = value;
    this.label = label;
  }
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, ControlValueAccessor {

    _options: string[] = [];
    get options(): string[] {
        return this._options;
    }
    @Input() set options(value: string[]) {
        this._options = value;
        this.filteredOptions = value;
    }

    @Input() selected: number = 0;
    @Input() className: string = '';
    @Input() placeholder: string = '';
    @Input() isReadOnly = false;
    @Input() fake = false;
    @Input() multiple = false;
    @Input() multipleSelected: Array<number> = [];
    @Input() searchMode: boolean = false;
    @Output() optSelect = new EventEmitter();
    isOpen = false;
    searchValue: string = '';
    filteredOptions: Array<string> = [];
    selectedOption = null;


    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;
    isSelectedValue: boolean = false;
    key: string = '';
    isFocused: boolean = false;

    /**
     *Creates an instance of DropdownComponent.
     * @memberof DropdownComponent
     */

    ngOnInit() {
      // Place default value in dropdown
      this.filteredOptions = this.options;
      this.setPlaceholder();

      if (this.multiple) {
        this.placeholder = this.filteredOptions.filter((item, index) => this.multipleSelected.includes(index)).join(", ") || 'Choose ';
        this.isOpen = false;
      }
    }

    filterDropdown(): void {
      this.filteredOptions = this.options.filter(option => option.toLowerCase().includes(this.searchValue.toLowerCase()) );
    }

    setPlaceholder(): void {
      if (!this.placeholder) {
        this.placeholder = 'Choose '
      }
      if (this.selected != -1) {
        this.placeholder = this.options[this.selected];
        this.isOpen = false;
      }
    }
    @HostListener('focus')
    focusHandler() {
      this.selected = 0;
      this.isFocused = true;
    }

    @HostListener('focusout')
    focusOutHandler() {
      this.isFocused = false;
    }

    @HostListener('document:keydown', ['$event'])
    keyPressHandle(event: KeyboardEvent) {

      if (this.isFocused) {
        this.key = event.code;
        switch (this.key) {
          case 'Space':
            this.isOpen = true;
            break;
          case 'ArrowDown':
            if (this.filteredOptions.length - 1 > this.selected) {
              this.selected = this.selected + 1;
            }
            break;
          case 'ArrowUp':
            if (this.selected > 0) {
              this.selected = this.selected - 1;
            }
            break;
          case 'Enter':
            if (this.selected > 0) {
              this.isSelectedValue = true;
              this.isOpen = false;
              this.onChangeCallback(this.selected);
              this.optSelect.emit(this.filteredOptions[this.selected]);
            }
            break;
        }
      }

    }

    /**
    * option selection
    * @param {string} selectedOption - text
    * @param {number} idx - current index of item
    * @param {any} event - object
    */
    optionSelect(selectedOption: string, idx: number, e: any) {
      e.stopPropagation();
      if (!this.fake) {
        if (this.multiple) {
          let index = this.multipleSelected.indexOf(idx); // real index
          if (this.multipleSelected.includes(idx)) {
            this.multipleSelected.splice(index, 1)
          } else {
            this.multipleSelected.push(idx);
            this.optSelect.emit(selectedOption);
          }
          this.placeholder = this.filteredOptions.filter((item, index) => this.multipleSelected.includes(index)).join(", ") || 'Choose ';

        } else {
          this.selected = idx;
          this.onChangeCallback(this.selected);
          this.optSelect.emit(selectedOption);
        }
        this.isSelectedValue = true;
        // this.placeholder = '';
        this.isOpen = false;
      } else {
        this.optSelect.emit(selectedOption);
        this.isOpen = false;
      }

    }

    /**
    * toggle the dropdown
    * @param {any} event object
    */
    toggle(e: any) {
      e.stopPropagation();
      // close all previously opened dropdowns, before open
      const allElems = document.querySelectorAll('.dropdown-wrapper');
      for (let i = 0; i < allElems.length; i++) {
        allElems[i].classList.remove('is-open');
      }
      this.isOpen = !this.isOpen;
      if (this.isOpen) { 
        if (this.searchMode) {
          setTimeout(() => {
            document.getElementById('search-input')?.focus();
          }, 100);
        }
      }
      if (this.selected >= 0) {
        // document.querySelector('#li' + this.selected).scrollIntoView(true);
      }
    }

    /**
    * dropdown click on outside
    */
    @HostListener('document: click', ['$event'])
    onClick() {
      this.isOpen = false;
    }

    /**
     * Method implemented from ControlValueAccessor and set default selected value
     * @param {*} obj
     * @memberof DropdownComponent
     */
    writeValue(obj: any): void {
      if (obj && obj !== '') {
        this.isSelectedValue = true;
        this.selected = obj;
      } else {
        this.isSelectedValue = false;
      }
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any) {
      this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any) {
      this.onTouchedCallback = fn;
    }

    setDisabledState?(isDisabled: boolean): void {

    }
}
