<header>
  <nav class="container">
    <div class="float-right">
      <label class="admin-panel" style="vertical-align: text-top;" *ngIf="(user$ | async)?.role === adminRole; else userPanel">
        <div class="admin-panel-inline">
          <app-dropdown
            [options]="adminLinks"
            [placeholder]="placeholder"
            [selected]="selectedPage"
            (optSelect)="changePage($event)"
            className="hide-placehodler">
          </app-dropdown>
          <label>{{ (user$ | async)?.displayName }}</label>
        </div>
      </label>
      <ng-template #userPanel>
        <label class="admin-panel" style="vertical-align: text-top;">
          <div class="admin-panel-inline">
            <app-dropdown [options]="userLinks" placeholder="Home " [selected]="selectedPage" (optSelect)="changePage($event)" className="hide-placehodler">
            </app-dropdown>
            <label>{{ (user$ | async)?.displayName }}</label>
          </div>
        </label>
      </ng-template>
      |
      <a class="link" (click)="onClickSignOut()">Log out</a>
    </div>

    <a class="header-link cursor-pointer" (click)="goHome()">
      :.: <br/>rezonate deep ai
    </a>

    <div style="clear: both;"></div>
  </nav>
</header>
