import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SettingsPage } from './settings.page';
import { TooltipModule } from 'app/shared/components/tooltip/tooltip.module';
import { ModalModule } from 'app/shared/components/modal/modal.module';

@NgModule({
    declarations: [SettingsPage],
    imports: [
      CommonModule,
      FormsModule,
      TooltipModule,
      ModalModule,
      RouterModule.forChild([
        {
          path: 'settings',
          component: SettingsPage,
          canLoad: [],
          data: {
            title: 'Settings',
            description: '',
            robots: 'index, follow',
          },
        },
      ])
    ]
})
export class SettingsModule {}
