import { AuthUser } from "app/shared/models/auth-user";

export enum StorageItem {
    Auth = 'App/auth',
    User = 'App/user'
  }


  export const getUser = (itemName: StorageItem): AuthUser => {
    const item = localStorage.getItem(itemName);
    return item ? JSON.parse(item) : new AuthUser();
  };

  export const getItem = (itemName: StorageItem): unknown | null => {
    const item = localStorage.getItem(itemName);
    return item ? JSON.parse(item) : null;
  };

  export const setItem = (itemName: StorageItem, value: unknown): void => {
    localStorage.setItem(itemName, JSON.stringify(value));
  };

  export const removeItem = (itemName: StorageItem): void => {
    localStorage.removeItem(itemName);
  };
