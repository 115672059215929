<section class="container flex items-center justify-center">

    <div class="contact-section margin-top-50">
      <h1>Settings</h1>
      <br />
      <div>
        <div><strong>Reminders </strong>
          <span
            class="cursor-pointer"
            [tooltip]="'More than one is possible. Click enter to save. Entry must be a valid email address.'">
            <i class="fa fa-info-circle" aria-hidden="true"></i>

          </span>
        </div>
        <br />
        <label>Default email-s to send reminders:</label>
        <input class="full-input" type="text" [(ngModel)]="email" (keyup.enter)="addEmail()" />
        <div *ngIf="form.reminderEmails.length">
          <br>
          <ul>

            <li *ngFor="let email of form.reminderEmails; let i = index">
              <a class="inverted-link" (click)="form.reminderEmails.splice(i, 1)" title="Delete">
                <i class="fa fa-times" aria-hidden="true"></i>
              </a>
              {{ email }}
            </li>
          </ul>

          <br>
        </div>

        <br />
      </div>
      <!-- <div>
        <div><strong>Weekly Digest </strong>
        <span
          class="cursor-pointer"
          [tooltip]="'More than one is possible. Click enter to save. Entry must be a valid email address.'">
          <i class="fa fa-info-circle" aria-hidden="true"></i>

        </span>
        </div>
        <br />
        <label>Send Weekly Digest of Upcoming Posts every [mark one or more days]:
        </label>
        <div style="margin-top: 3px;">
          | <a (click)="addDay('Mon')" [class.selected]="form.weeklyDigestDays.includes('Mon')" class="inverted-link selected">Mon</a> |
          <a (click)="addDay('Tue')" [class.selected]="form.weeklyDigestDays.includes('Tue')" class="inverted-link selected">Tue</a> |
          <a (click)="addDay('Wen')" [class.selected]="form.weeklyDigestDays.includes('Wen')" class="inverted-link selected">Wen</a> |
          <a (click)="addDay('Thu')" [class.selected]="form.weeklyDigestDays.includes('Thu')" class="inverted-link selected">Thu</a> |
          <a (click)="addDay('Fri')" [class.selected]="form.weeklyDigestDays.includes('Fri')" class="inverted-link selected">Fri</a> |
          <a (click)="addDay('Sat')" [class.selected]="form.weeklyDigestDays.includes('Sat')" class="inverted-link selected">Sat</a> |
          <a (click)="addDay('Sun')" [class.selected]="form.weeklyDigestDays.includes('Sun')" class="inverted-link selected">Sun</a> |
           at
           <input type="time" [(ngModel)]="form.weeklyDigestTime" id="weeklyDigestTime"> │
        </div>
        <br />
        <label>Default email-s to send Weekly Digest of Upcoming Posts:</label>
        <input class="full-input" [(ngModel)]="weeklyEmail" type="text" (keyup.enter)="addWeeklyEmail()" />
        <div *ngIf="form.weeklyDigestEmails.length">
          <br>
          <ul>
            <li *ngFor="let email of form.weeklyDigestEmails">{{ email }}</li>
          </ul>
          <br>
        </div>
        <div></div>
      </div> -->
      <br />
      <div>
        <div>
          <strong>Extend View </strong>
          <span
            class="cursor-pointer"
            [tooltip]="'Extend segments by default [if not marked then shrinked version is shown when app is loaded, mark one or more]'">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
          </span>
        </div>
        <br />

        <div>
          <div style="margin-top: 3px;">
            <!-- | <a (click)="form.aiColorSectionExpanded = !form.aiColorSectionExpanded; updateSettings()" [class.selected]="form.aiColorSectionExpanded" class="inverted-link selected">AI Color Sensor #</a> |
            <a (click)="form.emotionalIntensitySectionExpanded = !form.emotionalIntensitySectionExpanded; updateSettings()" [class.selected]="form.emotionalIntensitySectionExpanded" class="inverted-link">Emotional Intensity ☺</a> | -->
            <a (click)="form.receptivityForecastSectionExpanded = !form.receptivityForecastSectionExpanded" [class.selected]="form.receptivityForecastSectionExpanded" class="inverted-link">Receptivity ☼</a> |
            <a (click)="form.upgradeSectionExpanded = !form.upgradeSectionExpanded" [class.selected]="form.upgradeSectionExpanded" class="inverted-link">Upgrade ↥</a>

            </div>
        </div>
      </div>

      <br />
      <button (click)="updateSettings()">SAVE CHANGES</button>

      <br />
      <br />
      <br />
      <br />
      <br />



        <div><strong>About your brand</strong></div>
        <div class="about-brand-form">
          <div class="form-row">
            <label>Name*</label>
            <input type="text" [(ngModel)]="brandDetails.name" />
            <div></div>
          </div>
          <div class="form-row">
            <label>Website</label>
            <input type="text" [(ngModel)]="brandDetails.websiteAddress" />
            <div></div>
          </div>
          <div class="form-row">
            <label>Instagram</label>
            <input type="text" [(ngModel)]="brandDetails.instagramAddress" />
            <div></div>
          </div>
          <div class="form-row">
            <label>Facebook</label>
            <input type="text" [(ngModel)]="brandDetails.facebookAddress" />
            <div></div>
          </div>
          <div class="form-row">
            <label>Twitter</label>
            <input type="text" [(ngModel)]="brandDetails.twitterAddress" />
            <div></div>
          </div>
          <div class="form-row">
            <label>LinkedIn</label>
            <input type="text" [(ngModel)]="brandDetails.linkedinAddress" />
            <div></div>
          </div>
          <br />

          <button (click)="updateBrandDetails()">SAVE CHANGES</button>
          <!--
          <div class="form-row">
            <label><strong>About your product*</strong></label>
            <textarea rows="4"
              placeholder="Describe your product/brand in one or few sentences" [(ngModel)]="brandDetails.about"></textarea>
            <div></div>
          </div>
          <div class="form-row">
            <label><strong>Preferred length of generated posts</strong></label>
            <div class="bordered-div">
              <div>
                <input class="number-input" min="0" [(ngModel)]="brandDetails.preferredLengthOfPosts" type="number" />
                characters
              </div> -->
              <!-- <div>
                <em>This is going to be a default setting, but you can change it
                  anytime here, or when using AI Content Amplifier, for every specific
                  case
                </em>
              </div> -->
            <!-- </div>
            <div></div>
          </div> -->
          <!-- <div class="form-row">
            <label><strong>What do you need RezonateMe for?</strong></label>
            <div class="bordered-div bordered-div-last">
              <div>
                <a (click)="brandDetails.postingFrequency = 'DAILY_POSTING'" [class.selected]="brandDetails.postingFrequency === 'DAILY_POSTING'" class="inverted-link selected">Daily posting</a> /
                <a (click)="brandDetails.postingFrequency = 'MID_TERM_POSTING'" [class.selected]="brandDetails.postingFrequency === 'MID_TERM_POSTING'" class="inverted-link">Mid-term posting</a> /
                <a (click)="brandDetails.postingFrequency = 'LONG_TERM_POSTING'" [class.selected]="brandDetails.postingFrequency === 'LONG_TERM_POSTING'" class="inverted-link">Long-term posting</a>
              </div> -->
              <!-- <div>
                <em>
                  Our reports are crafted differently depending from what you use them
                  for. If you post frequently choose Daily posting. If you create
                  content that you usually publish few times per month choose Mid-term
                  posting. If you post once per month or less choose Long-term
                  posting.

                  <br /><br />
                  You can change this anytime. Changes will take effect in a next
                  report.
                </em>
                <br />
              </div> -->
            <!-- </div>
            <div></div>
          </div> -->
        </div>
        <!-- <p>*required</p> -->
      </div>

  <br />
  <br />
  <br />
  <br />
  <br />
</section>

<app-modal id="info-modal">
  <div class="modal-header">
    {{ modalTitle }}
  </div>
  <div class="modal-body">
    <p [innerHTML]="modalMessage"></p>
  </div>
  <div class="modal-footer">
    <button (click)="closeModal('info-modal');">OK</button>
  </div>
</app-modal>
